.App{
  font-family: Poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.emphasize {
  color: #e76c90;
  font-weight: 600;
}

.important {
  color: #e76c90;
  font-weight: 600;
}
.highlight{
  color:#2c304d;
}