.page-item.active .page-link{
    background-color: #6238a1;
    border-color: #6238a1;
  }

  .page-link{
    color: #6238a1;
  }
  .btn-theme{
    background-color: #915edf;
    color: white
  }
  .page-link:focus, .page-link:hover {
    color: #915edf;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd;
  }
